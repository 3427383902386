import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import projectsReducer from "../features/projects/projectsDataSlice";
import stacksReducer from "../features/stacks/stacksSlice";
import typesReducer from "../features/types/typesSlice";
import membersReducer from "../features/members/membersSlice";
import resetPasswordReducer from "../features/user/resetPasswordSlice";
import projectsCountReducer from "../features/projects/projectsCountSlice";
import projectsQueryReducer from "../features/projects/projectsQuerySlice";
import membersCountReducer from "../features/members/membersCountSlice";
import clientsReducer from "../features/clients/clientsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    projects: projectsReducer,
    stacks: stacksReducer,
    types: typesReducer,
    members: membersReducer,
    resetPassword: resetPasswordReducer,
    projectsCount: projectsCountReducer,
    membersCount: membersCountReducer,
    projectsQuery: projectsQueryReducer,
    clients: clientsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
