import React from "react";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { motion } from "framer-motion";

interface ButtonDeleteProps {
  id: string;
  onClick: any;
}

export default function ButtonDelete({
  id,
  onClick,
}: ButtonDeleteProps): JSX.Element {
  return (
    <IconButton
      component={motion.div}
      whileHover={{ boxShadow: "0px 0px 7px" }}
      color="secondary"
      id={id}
      onClick={onClick}
      style={{
        width: 32,
        height: 32,
        margin: "0 .25rem",
        fontSize: "40px",
      }}
    >
      <RemoveCircleOutlineRoundedIcon style={{ fontSize: "inherit" }} />
    </IconButton>
  );
}
