import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config.js";
import IType from "../../interface/IType";

interface InitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  updateSelect: boolean;
  errorMessage?: string;
  data: IType[];
}

const initialState: InitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  updateSelect: false,
  errorMessage: "",
  data: [
    {
      label: "",
      id: 0,
    },
  ],
};

export const fetchTypes = createAsyncThunk(
  "types/fetchTypes",
  async (): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/types`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const createType = createAsyncThunk(
  "types/createType",
  async (type: string, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/types`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          label: type,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchTypes());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const editType = createAsyncThunk(
  "stacks/editType",
  async (
    { id, label }: { id: number; label: string },
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/types/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          label,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchTypes());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const deleteType = createAsyncThunk(
  "types/deleteType",
  async (id: number, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/types/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchTypes());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const typesSlice = createSlice({
  name: "typesData",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.updateSelect = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypes.fulfilled, (state, action) => {
      state.data = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchTypes.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchTypes.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(createType.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(createType.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createType.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(deleteType.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(deleteType.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(deleteType.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(editType.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(editType.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(editType.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const typesSelector = (state: RootState) => state.types;

export default typesSlice.reducer;
