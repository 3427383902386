import React from "react";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import IProjectData from "../../interface/IProjectData";
import { Control } from "react-hook-form";

type ProjectControllerProps = {
  propName:
    | "name"
    | "supervisor"
    | "git"
    | "teamwork"
    | `team.members.${number}.member.name`
    | `team.members.${number}.responsibilities`
    | `history.${number}.description`;
  defaultValue?: string;
  inputClass: string;
  label: string;
  helperText?: string;
  control: Control<IProjectData>;
  error: boolean;
  required: boolean;
};

export default function ProjectController({
  propName,
  defaultValue,
  inputClass,
  label,
  helperText,
  control,
  error,
  required,
}: ProjectControllerProps): JSX.Element {
  return (
    <Controller
      name={propName}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          className={`${inputClass} ${required ? "required" : ""}`}
          margin="normal"
          variant="outlined"
          placeholder={label}
          label={label}
          error={error}
          helperText={helperText}
          autoComplete="false"
        />
      )}
    />
  );
}
