import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import "../styles/ProjectsTable.scss";
import { convertDateStringToUIDate } from "../utils/convertDateStringToUIDate";
import {
  deleteProject,
  clearState,
  fetchProjectsPage,
} from "../features/projects/projectsDataSlice";
import Loader from "react-loader-spinner";
import { motion } from "framer-motion";
import { iconButtonStyle } from "../utils/iconButtonStyle";
import Filter from "./Filter/Filter";
import PaginationLink from "./PaginationLink";
import { fetchProjectsCount } from "../features/projects/projectsCountSlice";
import SearchProject from "./SearchProject";

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export const StyledTableHeadRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
    },
  })
)(TableCell);

export default function ProjectsTable(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjectsPage(0));
    dispatch(fetchProjectsCount());
  }, [dispatch]);

  const projectsCount = useAppSelector((state) => state.projectsCount.data);

  const project = useAppSelector((state) => state.projects.list);

  const {
    data: projectList,
    isSuccess,
    isFetching,
    isPaginationDisplay,
  } = project;

  let history = useHistory();

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);

  const [projectToDelete, setProjectToDelete] = useState<string>();

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: "50vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
        transition: { type: "tween" },
      }}
      exit={{
        x: -200,
        opacity: 0,
        transition: { duration: 0.5 },
      }}
      className="table-container"
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć projekt?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Nie</Button>
          <Button
            onClick={() => {
              dispatch(deleteProject(+projectToDelete!));
              handleClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{ display: { xs: "none", md: "block" } }}
        style={{ width: "18%" }}
      >
        <Filter />
      </Box>
      <div className="table__content">
        <Grid
          container
          alignContent="space-between"
          style={{
            margin: "1rem 0",
          }}
        >
          <Grid item xs={12} sm={5} style={{ margin: "0 auto" }}>
            <SearchProject />
          </Grid>
          <Grid
            item
            xs={2}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/form")}
            >
              Dodaj projekt
            </Button>
          </Grid>
        </Grid>
        {isSuccess && projectList?.length > 0 ? (
          <>
            <TableContainer
              component={Paper}
              style={{
                margin: "1rem auto",
                background: "rgba(255, 255, 255, 0.25)",
                borderRadius: "5px",
                boxShadow: " 0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                backdropFilter: "blur(4px)",
              }}
            >
              <Table>
                <TableHead>
                  <StyledTableHeadRow>
                    <StyledTableCell align="center" size="small">
                      ID
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Nazwa Projektu
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Data Wykonania
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Opiekun Projektu
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Typ Projektu
                    </StyledTableCell>
                    <StyledTableCell align="center">Akcje</StyledTableCell>
                  </StyledTableHeadRow>
                </TableHead>
                <TableBody>
                  {projectList.map(
                    ({ id, name, created, supervisor, type }) => {
                      return (
                        <StyledTableRow key={id}>
                          <TableCell align="center" size="small">
                            {id}
                          </TableCell>
                          <TableCell align="center">{name}</TableCell>
                          <TableCell align="center">
                            {created && convertDateStringToUIDate(created)}
                          </TableCell>
                          <TableCell align="center">{supervisor}</TableCell>
                          <TableCell align="center">{type?.label}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="usuń projekt" placement="top" arrow>
                              <IconButton
                                component={motion.div}
                                whileHover={{ boxShadow: "1px 1px 5px" }}
                                id={`${id}`}
                                onClick={(e: any) => {
                                  setOpen(true);
                                  setProjectToDelete(e.currentTarget.id);
                                }}
                                style={iconButtonStyle("#d32f2f")}
                              >
                                <DeleteForeverTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="edytuj projekt"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                component={motion.div}
                                whileHover={{ boxShadow: "1px 1px 5px" }}
                                onClick={() => {
                                  history.push(`/edit/${id}`);
                                  dispatch(clearState());
                                }}
                                style={iconButtonStyle("#ff9800")}
                              >
                                <EditRoundedIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="szczegóły projektu"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                component={motion.div}
                                whileHover={{ boxShadow: "1px 1px 5px" }}
                                onClick={() => {
                                  history.push(`/project/${id}/description`);
                                  dispatch(clearState());
                                }}
                                style={iconButtonStyle("#64b5f6")}
                              >
                                <AssignmentIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isPaginationDisplay && (
                <PaginationLink
                  count={projectsCount}
                  initialEntries={"/projects"}
                  fetch={fetchProjectsPage}
                />
              )}
            </div>
          </>
        ) : isFetching ? (
          <div style={{ margin: "8rem auto" }}>
            <h3>Pobieram listę projektu</h3>
            <Loader type="TailSpin" />
          </div>
        ) : (
          <h2 style={{ margin: "0 auto" }}>Brak projektów do wyświetlenia</h2>
        )}
      </div>
    </motion.div>
  );
}
