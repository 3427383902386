import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import IProjectData from "../../interface/IProjectData";
import moment from "moment";
import { UseFormSetValue } from "react-hook-form";

type MyDataPickerProps = {
  existingProjectData?: IProjectData;
  inputClass: string;
  setValue: UseFormSetValue<IProjectData>;
  label: string;
  propName: string;
  watch: <propName>(
    name: propName,
    defaultValue?: string | undefined
  ) => string | undefined;
};

export default function MyDatePicker({
  existingProjectData,
  inputClass,
  setValue,
  watch,
  label,
  propName,
}: MyDataPickerProps): JSX.Element {
  useEffect(() => {
    setValue(
      propName as keyof IProjectData,
      moment(new Date()).format("YYYY-MM-DD")
    );
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={inputClass}
        disableToolbar
        inputVariant="outlined"
        views={["year", "month"]}
        format="MM/yyyy"
        margin="normal"
        id={`date-picker-${propName}`}
        label={label}
        value={
          watch(propName)
            ? Date.parse(watch(propName)!)
            : existingProjectData &&
              existingProjectData[propName as keyof IProjectData]
        }
        onChange={(e, option) => {
          e &&
            setValue(
              propName as keyof IProjectData,
              moment(e).format("YYYY-MM-DD")
            );
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
