import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { motion } from "framer-motion";
import Lightbox from "react-image-lightbox";
import { useAppDispatch } from "../app/hooks";
import { API_URL } from "../config";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverRounded";
import { iconButtonStyle } from "../utils/iconButtonStyle";
import { editScreens } from "../features/projects/projectsDataSlice";
import "react-image-lightbox/style.css";

interface ScreensGalleryProps {
  id: number;
  screens: any;
}

export default function ScreensGallery({
  id,
  screens,
}: ScreensGalleryProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const [isDeleteScreensOpen, setIsDeleteScreensOpen] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const screensURLs: { small: string; full: string; screenId: number }[] =
    screens.map((screen: any) => {
      return {
        small: API_URL + (screen?.formats?.thumbnail?.url || screen.url),
        full: API_URL + (screen?.formats?.large?.url || screen.url),
        screenId: screen.id,
      };
    });

  const screensIds = screens.map(({ id }: { id: number }) => id);

  const [currentScreens, setCurrentScreens] = useState<number[]>(screensIds);

  const removeScreenId = (id: number) => {
    setCurrentScreens(
      currentScreens.filter((currentScreen) => currentScreen !== id)
    );
  };

  const [screenIdToDelete, setScreenIdToDelete] = useState<number>();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleClose = () => setOpenDialog(false);
  const handleOpen = () => setOpenDialog(true);

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć obraz?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Nie</Button>
          <Button
            onClick={() => {
              removeScreenId(screenIdToDelete!);
              handleClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {screensURLs.map(
          (
            { small, screenId }: { small: string; screenId: number },
            displayedScreenId: number
          ) => {
            function Images(): JSX.Element {
              return (
                <div
                  style={{
                    margin: "1rem",
                    position: "relative",
                    width: "fit-content",
                    display: currentScreens.includes(screenId)
                      ? "block"
                      : "none",
                  }}
                >
                  <img
                    onClick={() => {
                      setPhotoIndex(displayedScreenId);
                      setIsOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                    src={`${small}`}
                    alt="Application screenshot"
                  />
                  {isDeleteScreensOpen && (
                    <Tooltip title="usuń obraz" placement="top" arrow>
                      <IconButton
                        component={motion.div}
                        whileHover={{ boxShadow: "1px 1px 5px" }}
                        id={`${screenId}`}
                        onClick={(e: any) => {
                          handleOpen();
                          setScreenIdToDelete(screenId);
                        }}
                        style={{
                          ...iconButtonStyle("#d32f2f"),
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          opacity: ".7",
                          transform:
                            "translateX(-50%) translateY(-50%) scale(2)",
                        }}
                      >
                        <DeleteForeverTwoToneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              );
            }
            return <Images key={`screen_${displayedScreenId}`} />;
          }
        )}
      </div>
      {Boolean(screens.length) ? (
        isDeleteScreensOpen ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (currentScreens.length !== screens.length) {
                dispatch(editScreens({ id, screens: currentScreens }));
              }
              setIsDeleteScreensOpen(false);
            }}
          >
            Zapisz
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsDeleteScreensOpen(true)}
          >
            Edytuj
          </Button>
        )
      ) : null}
      {isOpen && (
        <Lightbox
          mainSrc={screensURLs[photoIndex].full}
          nextSrc={screensURLs[(photoIndex + 1) % screensURLs.length].full}
          prevSrc={
            screensURLs[
              (photoIndex + screensURLs.length - 1) % screensURLs.length
            ].full
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + screensURLs.length - 1) % screensURLs.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % screensURLs.length)
          }
        />
      )}
    </div>
  );
}
