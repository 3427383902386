import {
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  StyledTableHeadRow,
  StyledTableRow,
} from "./ProjectsTable";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { iconButtonStyle } from "../utils/iconButtonStyle";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import Loader from "react-loader-spinner";
import { deleteType, editType, fetchTypes } from "../features/types/typesSlice";
import IType from "../interface/IType";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useStyles } from "./ProjectTimeline";
import CloseRounded from "@material-ui/icons/CloseRounded";

const buttonHover = {
  scale: 1.1,
  transition: { duration: 0.3 },
  boxShadow: "1px 1px 7px",
};
const buttonTap = { scale: 0.9 };

interface EditTypePropProps {
  handleClick: () => void;
}

export default function EditTypeProp({
  handleClick,
}: EditTypePropProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const types = useAppSelector((state) => state.types);

  const { data: typesList, isSuccess, isFetching } = types;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IType>();

  const [currentType, setCurrentType] = useState<IType>();

  const onSubmit: SubmitHandler<IType> = (data) => {
    currentType && (data.id! = currentType.id!);
    dispatch(editType(data));
    handleClose();
  };

  const findCurrentType = (id: number): void =>
    setCurrentType(typesList.find((elem) => elem.id === id));

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [typeToDelete, setTypeToDelete] = useState<number>();
  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć projekt?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>Nie</Button>
          <Button
            onClick={() => {
              dispatch(deleteType(typeToDelete!));
              handleDialogClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Type editor"
        aria-describedby="Edit projects type"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper
            className={classes.modalStyle}
            style={{
              position: "relative",
              minHeight: "250px",
              minWidth: "300px",
              maxWidth: "400px",
              margin: "4rem auto",
            }}
          >
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="h3" color="primary" align="center">
                  Technologia
                </Typography>
                <Controller
                  name={`label`}
                  shouldUnregister
                  control={control}
                  rules={{ required: true }}
                  defaultValue={currentType?.label}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Technologia"
                      label="Technologia"
                      error={Boolean(errors?.label)}
                      helperText={errors?.label && "Podaj nową wartość."}
                    />
                  )}
                />
              </Grid>
              <Grid container>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ margin: "1rem auto" }}
                  type="submit"
                >
                  Zatwierdź
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Modal>
      {typesList && isSuccess ? (
        <>
          <TableContainer component={Paper} style={{ margin: "1rem auto" }}>
            <Button
              color="secondary"
              style={{
                position: "absolute",
                right: "0",
              }}
              onClick={() => handleClick()}
            >
              <CloseRounded />
            </Button>
            <Table>
              <TableHead>
                <StyledTableHeadRow>
                  <StyledTableCell align="center" size="small">
                    ID
                  </StyledTableCell>
                  <StyledTableCell align="center">Technologia</StyledTableCell>
                  <StyledTableCell align="center">Akcje</StyledTableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {typesList.map(({ id, label }) => {
                  return (
                    <StyledTableRow key={id}>
                      <TableCell align="center" size="small">
                        {id}
                      </TableCell>
                      <TableCell align="center">{label}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="usuń" placement="top" arrow>
                          <IconButton
                            component={motion.div}
                            whileHover={buttonHover}
                            whileTap={buttonTap}
                            id={`${id}`}
                            onClick={() => {
                              setTypeToDelete(id);
                              handleDialogOpen();
                            }}
                            style={iconButtonStyle("#d32f2f")}
                          >
                            <DeleteForeverTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="edytuj" placement="top" arrow>
                          <IconButton
                            component={motion.div}
                            whileHover={buttonHover}
                            whileTap={buttonTap}
                            id={`${id}`}
                            onClick={() => {
                              findCurrentType(id);
                              handleOpen();
                            }}
                            style={iconButtonStyle("#ff9800")}
                          >
                            <EditRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : isFetching ? (
        <div style={{ margin: "8rem auto" }}>
          <h3>Pobieram listę technologii</h3>
          <Loader type="TailSpin" />
        </div>
      ) : (
        <h2 style={{ margin: "0 auto" }}>
          Brak typów projektu do wyświetlenia
        </h2>
      )}
    </div>
  );
}
