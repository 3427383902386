import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchTypes } from "../../features/types/typesSlice";
import { ReactComponent as Arrow } from "../../assets/down-arrow.svg";

interface FilterByTypeProps {
  updateType: (id: number) => void;
  type: number[];
}

export default function FilterByType({
  updateType,
  type,
}: FilterByTypeProps): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const typesData = useAppSelector((state) => state.types.data);

  const [isShowAll, setIsShowAll] = useState<Boolean>(false);

  return (
    <div className="filter__box">
      <Typography component="legend" variant="h6" className="filter__legend">
        Typ projektu
      </Typography>
      <FormGroup style={{ marginLeft: "1rem" }}>
        {typesData &&
          typesData.map(({ id, label }, index) =>
            isShowAll || index < 5 ? (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    style={{ padding: ".3rem" }}
                    color="primary"
                    id={`${id}`}
                    checked={Boolean(type.find((elem) => elem === id))}
                    onChange={(e) => updateType(Number(e.currentTarget.id))}
                  />
                }
                label={label}
              />
            ) : null
          )}
      </FormGroup>
      {typesData && typesData.length > 5 && (
        <Button
          className="filter__show-more-button"
          onClick={() => setIsShowAll(!isShowAll)}
        >
          <Arrow
            style={{
              marginRight: "5px",
              transform: isShowAll ? "rotate(180deg)" : undefined,
            }}
          />
          {isShowAll ? "Ukryj" : "Pokaż więcej"}
          <Arrow
            style={{
              marginLeft: "5px",
              transform: isShowAll ? "rotate(180deg)" : undefined,
            }}
          />
        </Button>
      )}
    </div>
  );
}
