import React, { useState } from "react";
import "../../styles/Filter.scss";
import { useAppDispatch } from "../../app/hooks";
import { Button } from "@material-ui/core";
import {
  clearState,
  fetchFiltredProjects,
  fetchProjectsPage,
} from "../../features/projects/projectsDataSlice";
import FilterByStack from "./FilterByStack";
import FilterByType from "./FilterByType";
import { createUpdateStateFunction } from "../../utils/updateState";

export default function Filter(): JSX.Element {
  const dispatch = useAppDispatch();

  const [stack, setStack] = useState<number[]>([]);

  const [type, setType] = useState<number[]>([]);

  const updateType = createUpdateStateFunction(type, setType);
  const updateStack = createUpdateStateFunction(stack, setStack);

  return (
    <div className="filter">
      <Button
        color="primary"
        variant="contained"
        style={{ lineHeight: "1", margin: "0rem auto 1rem" }}
        onClick={() => {
          setStack([]);
          setType([]);
          dispatch(fetchProjectsPage(0));
        }}
      >
        Usuń filtry
      </Button>
      <FilterByStack updateStack={updateStack} stack={stack} />
      <FilterByType updateType={updateType} type={type} />
      <Button
        color="primary"
        variant="contained"
        style={{ lineHeight: "1" }}
        onClick={() => {
          dispatch(clearState());
          dispatch(fetchFiltredProjects({ stack, type }));
        }}
      >
        Filtruj
      </Button>
    </div>
  );
}
