import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  Grid,
  Button,
  Modal,
  TextField,
  Typography,
  TableBody,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import IWorker from "../../interface/IWorker";
import {
  clearState,
  createMember,
  deleteMember,
  editMember,
  fetchMembers,
  fetchMembersPage,
} from "../../features/members/membersSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { iconButtonStyle } from "../../utils/iconButtonStyle";
import {
  StyledTableCell,
  StyledTableHeadRow,
  StyledTableRow,
} from "../ProjectsTable";
import { useStyles } from "../ProjectTimeline";
import Loader from "react-loader-spinner";
import validator from "validator";
import PaginationLink from "../PaginationLink";
import { fetchMembersCount } from "../../features/members/membersCountSlice";

const buttonHover = {
  scale: 1.1,
  transition: { duration: 0.3 },
  boxShadow: "1px 1px 7px",
};
const buttonTap = { scale: 0.9 };

export default function List(): JSX.Element {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IWorker>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const initialState = { name: "", phone: "", mail: "", company: "" };

  const handleClose = () => {
    setOpen(false);
    reset(initialState);
  };

  const dispatch = useAppDispatch();

  const validatePhoneNumber = (number: string) => {
    const isValidPhoneNumber = validator.isMobilePhone(number, "any");
    return isValidPhoneNumber;
  };

  const onSubmit: SubmitHandler<IWorker> = (data) => {
    data.id ? dispatch(editMember(data)) : dispatch(createMember(data));
    handleClose();
    dispatch(clearState());
    dispatch(fetchMembers());
  };

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchMembersPage(0));
    dispatch(fetchMembersCount());
  }, [dispatch]);

  const membersState = useAppSelector((state) => state.members);

  const { data: membersList, isSuccess, isFetching } = membersState;

  const [currentWorker, setCurrentWorker] = useState<IWorker | undefined>();

  useEffect(() => reset(currentWorker), [currentWorker, reset]);

  const filterWorkers = (id: number) => {
    setCurrentWorker(membersList.find((elem) => elem.id === id));
  };

  const workersCount = useAppSelector((state) => state.membersCount.data);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<string>();
  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  return (
    <motion.div
      initial={{
        width: "50%",
        opacity: 0,
        x: "50vw",
      }}
      animate={{
        width: "100%",
        opacity: 1,
        x: 0,
        transition: { type: "tween" },
      }}
      exit={{
        x: -200,
        opacity: 0,
        transition: { duration: 0.5 },
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć projekt?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>Nie</Button>
          <Button
            onClick={() => {
              dispatch(deleteMember(+memberToDelete!));
              handleDialogClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <motion.form
          onSubmit={handleSubmit(onSubmit)}
          initial={{
            scale: ".5",
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            x: "calc(50% - 25vw)",
            y: "calc(50% + 25vh)",
            transition: { type: "tween", duration: 0.6 },
          }}
        >
          <Paper
            className={classes.modalStyle}
            style={{
              minHeight: "500px",
              background: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0 17px 20px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          >
            <Grid
              container
              justifyContent="center"
              style={{ margin: "0 auto" }}
            >
              <Grid item>
                <Typography variant="h3" color="primary" align="center">
                  Dane pracownika
                </Typography>
                <Controller
                  name={`name`}
                  shouldUnregister
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="required"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Imię i nazwisko"
                      label="Imię i nazwisko"
                      error={Boolean(errors?.name)}
                      helperText={
                        errors?.name && "Podaj imię i nazwisko pracownika."
                      }
                    />
                  )}
                />
                <Controller
                  name={`company`}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Firma"
                      label="Firma"
                    />
                  )}
                />
                <Controller
                  name={`mail`}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="E-mail"
                      label="E-mail"
                    />
                  )}
                />
                <Controller
                  name={`phone`}
                  control={control}
                  defaultValue={""}
                  rules={{
                    minLength: 6,
                    validate: (value) => {
                      if (value) {
                        return validatePhoneNumber(value);
                      }
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="tel"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Numer telefonu"
                      label="Numer telefonu"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone && "Podaj numer telefonu"}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "1rem auto" }}
                type="submit"
              >
                Zatwierdź
              </Button>
            </Grid>
          </Paper>
        </motion.form>
      </Modal>
      <Grid container justifyContent="flex-end" style={{ margin: "1rem 0" }}>
        <Button
          component={motion.div}
          color="primary"
          variant="contained"
          onClick={handleOpen}
        >
          Dodaj pracownika
        </Button>
      </Grid>
      {membersList?.length && isSuccess ? (
        <>
          <TableContainer
            component={Paper}
            style={{
              margin: "1rem auto",
              background: "rgba(255, 255, 255, 0.25)",
              borderRadius: "5px",
              boxShadow: " 0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableHeadRow>
                  <StyledTableCell align="center" size="small">
                    ID
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Imię i Nazwisko
                  </StyledTableCell>
                  <StyledTableCell align="center">Firma</StyledTableCell>
                  <StyledTableCell align="center">E-mail</StyledTableCell>
                  <StyledTableCell align="center">Nr. telefonu</StyledTableCell>
                  <StyledTableCell align="center">Akcje</StyledTableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {membersList.map(({ id, name, mail, phone, company }) => {
                  return (
                    <StyledTableRow key={id}>
                      <TableCell align="center" size="small">
                        {id}
                      </TableCell>
                      <TableCell align="center">{name}</TableCell>
                      <TableCell align="center">{company}</TableCell>
                      <TableCell align="center">{mail}</TableCell>
                      <TableCell align="center">{phone}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="usuń pracownika" placement="top" arrow>
                          <IconButton
                            component={motion.div}
                            whileHover={buttonHover}
                            whileTap={buttonTap}
                            id={`${id}`}
                            onClick={(e: any) => {
                              setMemberToDelete(e.currentTarget.id);
                              handleDialogOpen();
                            }}
                            style={iconButtonStyle("#d32f2f")}
                          >
                            <DeleteForeverTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="edytuj pracownika"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            component={motion.div}
                            whileHover={buttonHover}
                            whileTap={buttonTap}
                            id={`${id}`}
                            onClick={() => {
                              filterWorkers(id!);
                              handleOpen();
                            }}
                            style={iconButtonStyle("#ff9800")}
                          >
                            <EditRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationLink
            count={workersCount}
            initialEntries={"/workers"}
            fetch={fetchMembersPage}
          />
        </>
      ) : isFetching ? (
        <div style={{ margin: "8rem auto" }}>
          <h3>Pobieram listę pracowników</h3>
          <Loader type="TailSpin" />
        </div>
      ) : (
        <h2 style={{ margin: "0 auto" }}>Brak pracowników do wyświetlenia</h2>
      )}
    </motion.div>
  );
}
