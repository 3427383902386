import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config.js";

interface ICountInitialState {
  data: number;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: string;
}

const initialState: ICountInitialState = {
  data: 0,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

export const fetchMembersCount = createAsyncThunk(
  "projects/fetchMembersCount",
  async (): Promise<number | undefined> => {
    try {
      const response = await fetch(`${API_URL}/members/count`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const membersCountSlice = createSlice({
  name: "membersCount",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMembersCount.fulfilled, (state, action) => {
      state.data = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchMembersCount.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchMembersCount.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearState } = membersCountSlice.actions;

export const membersCountSelector = (state: RootState) => state;

export default membersCountSlice.reducer;
