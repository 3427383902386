import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config.js";
import IStack from "../../interface/IStack";

interface InitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  updateSelect: boolean;
  errorMessage?: string;
  data: IStack[];
}

const initialState: InitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  updateSelect: false,
  errorMessage: "",
  data: [],
};

export const fetchStacks = createAsyncThunk(
  "stacks/fetchStacks",
  async (): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/stacks`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const createStack = createAsyncThunk(
  "stacks/createStack",
  async (stack: string, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/stacks`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          label: stack,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchStacks());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const deleteStack = createAsyncThunk(
  "stacks/deleteStack",
  async (id: number, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/stacks/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchStacks());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const editStack = createAsyncThunk(
  "stacks/editStack",
  async (
    { id, label }: { id: number; label: string },
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/stacks/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          label,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchStacks());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const stacksSlice = createSlice({
  name: "stacksData",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.updateSelect = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStacks.fulfilled, (state, action) => {
      state.data = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchStacks.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchStacks.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(createStack.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(createStack.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createStack.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(deleteStack.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(deleteStack.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(deleteStack.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(editStack.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(editStack.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(editStack.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const stacksSelector = (state: RootState) => state.stacks;

export default stacksSlice.reducer;
