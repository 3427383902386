import React, { useState, useEffect } from "react";
import {
  useForm,
  SubmitHandler,
  useFieldArray,
  Controller,
  useController,
} from "react-hook-form";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IProjectData from "../interface/IProjectData";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  addProject,
  editProject,
  clearState,
} from "../features/projects/projectsDataSlice";
import { createStack, fetchStacks } from "../features/stacks/stacksSlice";
import { useHistory } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import moment from "moment";
import { motion } from "framer-motion";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Team from "./Form/Team";
import History from "./Form/History";
import ProjectDatePicker from "./Form/ProjectDatePicker";
import ProjectController from "./Form/ProjectController";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { createType, fetchTypes } from "../features/types/typesSlice";
import "../styles/ProjectForm.scss";
import EditStackProp from "./EditStackProp";
import EditTypeProp from "./EditTypeProp";
import {
  styleSelectControl,
  styleSelectContainer,
  styleSelectInput,
} from "../utils/reactSelectStyle";
import { fetchClients } from "../features/clients/clientsSlice";
import ScreensGallery from "./ScreensGallery";
import { ErrorBoundary } from "./ErrorBoundary";

const buttonStyle = {
  height: 50,
  width: 150,
  margin: "1rem auto",
};

const useStyles = makeStyles({
  inputClass: {
    background: "rgba(255, 255, 255, .9)",
    borderRadius: "10px",
    width: "100%",
    margin: ".6rem auto",
    boxShadow: " 2px 2px 10px 0 rgba(31, 38, 135, 0.37)",
  },
});

const errorMUIClassName =
  "MuiFormHelperText-root MuiFormHelperText-contained Mui-error";

type OptionsType = { label: string | undefined; value: number | undefined };

type ProjectFormProps = {
  existingProjectData?: IProjectData;
};

export default function ProjectForm({
  existingProjectData,
}: ProjectFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IProjectData>({
    defaultValues: {
      team: {
        members: existingProjectData?.team.members.length
          ? existingProjectData.team.members
          : [{ member: { name: "" }, responsibilities: "" }],
      },
      history:
        existingProjectData?.history && existingProjectData?.history.length
          ? existingProjectData.history
          : [
              {
                description: "",
                date: moment(new Date()).format("YYYY-MM-DD"),
              },
            ],
    },
  });

  const classes = useStyles();

  const commonDatePickerProps = {
    existingProjectData,
    setValue,
    watch,
    inputClass: classes.inputClass,
  };

  const teamArr = useFieldArray({
    control,
    name: "team.members",
  });
  const historyArr = useFieldArray({
    control,
    name: "history",
  });

  const dispatch = useAppDispatch();

  let history = useHistory();

  const actualId = existingProjectData && existingProjectData.id;

  const onSubmit: SubmitHandler<IProjectData> = (data) => {
    if (!existingProjectData) {
      dispatch(addProject(data));
      dispatch(clearState());
      history.push("/projects");
    } else {
      actualId && (data.id = actualId);
      dispatch(editProject(data));
      dispatch(clearState());
      history.push("/projects");
    }
  };

  useEffect(() => {
    existingProjectData &&
      setValue(`supervisor`, existingProjectData?.supervisor);
    existingProjectData && setValue("created", existingProjectData?.created);
    if (type) {
      setValue("type", { label: type.label!, id: type.value! });
    }
    existingProjectData &&
      setValue(`client.id`, existingProjectData?.client?.id);
    setValue("stacks", stack);
    setValue("description", existingProjectData?.description || "");
    register("type", {
      required: "Wybierz typ projektu",
    });
    register("stacks", {
      required: "Wybierz technologię projektu",
    });
    register("created", {
      required: "Wybierz datę powstania projektu",
    });
    register("description", {
      required: "Uzupełnij opis projektu",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editorState, setEditorState] = useState(() =>
    existingProjectData
      ? EditorState.createWithContent(
          convertFromHTML(existingProjectData?.description)
        )
      : EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState("");

  useEffect(() => {
    dispatch(fetchStacks());
    dispatch(fetchTypes());
    dispatch(fetchClients());
  }, [dispatch]);

  const stacksData = useAppSelector((state) => state.stacks.data);
  const typesData = useAppSelector((state) => state.types.data);

  const stackOptions: { label: string; value: number; id: number }[] =
    stacksData?.map(({ label, id }) => {
      return { label, value: id, id };
    });

  const typesOptions: OptionsType[] = typesData?.map(({ label, id }) => {
    return { label: label, value: id };
  }) || [{ label: "", value: 0 }];

  const handleEditorChange = (state: React.SetStateAction<EditorState>) => {
    setEditorState(state);
    convertContentToHTML();
    setValue("description", convertedContent);
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const defaultStackSelectValue = existingProjectData?.stacks.map(
    ({ label, id }) => {
      return { label: label, value: id, id };
    }
  );

  const defaultTypeSelectValue = {
    label: existingProjectData?.type.label,
    value: existingProjectData?.type.id,
  };

  const [stack, setStack] = useState<typeof stackOptions>(
    defaultStackSelectValue || []
  );

  const [type, setType] = useState<OptionsType>(defaultTypeSelectValue);

  const updateStackSelect = useAppSelector(
    (state) => state.stacks.updateSelect
  );
  const updateTypeSelect = useAppSelector((state) => state.types.updateSelect);

  useEffect(() => {
    if (updateStackSelect) {
      setStack([...stack, stackOptions[stackOptions.length - 1]]);
    }
  }, [stacksData]);

  useEffect(() => {
    if (updateTypeSelect) {
      setType({
        label: typesData[typesData.length - 1].label,
        value: typesData[typesData.length - 1].id,
      });
    }
  }, [typesData]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    setValue("stacks", stack);
  }, [stack, setValue]);

  useEffect(() => {
    if (type) {
      setValue("type", { label: type.label!, id: type.value! });
    }
  }, [type, setValue]);

  const handleFormat = (inputValue: string) => {
    return `Dodaj "${inputValue}"`;
  };

  const [editStackOpen, setEditStackOpen] = useState<boolean>(false);
  const handleEditStackPropOpen = () => {
    setEditStackOpen(false);
  };

  const [editTypeOpen, setEditTypeOpen] = useState<boolean>(false);
  const handleEditTypePropOpen = () => {
    setEditTypeOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  const membersData = useAppSelector((state) => state.members.data);
  const supervisorOptions: { label: string; value: string }[] =
    membersData?.map(({ name }) => {
      return { label: name, value: name };
    }) || [{ label: "", value: "" }];

  const clientData = useAppSelector((state) => state.clients.data.list);
  const clientsOptions: { label: string; value: number }[] = clientData?.map(
    ({ name, id }) => {
      return { label: name, value: id! };
    }
  ) || [{ label: "", value: 0 }];

  const [selectedFile, setSelectedFile] = useState<FileList>();
  const [preview, setPreview] = useState<string[]>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrls = Array.from(selectedFile).map((file: any) =>
      URL.createObjectURL(file)
    );
    setPreview(objectUrls);

    return () =>
      Array.from(selectedFile).forEach((file: any) =>
        URL.revokeObjectURL(file)
      );
  }, [selectedFile]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files);
  };

  const FileInput = ({ control, name }: { control: any; name: string }) => {
    const { field } = useController({ control, name });
    const [value, setValue] = useState<any>();
    return (
      <>
        <label htmlFor="file-input" className="form__file-input">
          Wybierz pliki...
        </label>
        <input
          id="file-input"
          multiple
          type="file"
          value={value}
          onChange={(e) => {
            onSelectFile(e);
            setValue(e.target.value);
            field.onChange(e.target.files);
          }}
        />
      </>
    );
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      initial={{
        width: "50%",
        opacity: 0,
        x: "50vw",
      }}
      animate={{
        width: "100%",
        opacity: 1,
        x: 0,
        transition: { type: "tween" },
      }}
      exit={{
        x: -200,
        opacity: 0,
        transition: { duration: 0.5 },
      }}
      className="form-container"
      autoComplete="off"
    >
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Wprowadzone dane zostaną utracone. Czy napewno chcesz wrócić?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>Nie</Button>
          <Button
            onClick={() => {
              history.goBack();
              dispatch(clearState());
              handleDialogClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      {editStackOpen && <EditStackProp handleClick={handleEditStackPropOpen} />}
      {editTypeOpen && <EditTypeProp handleClick={handleEditTypePropOpen} />}
      {!editStackOpen && !editTypeOpen && (
        <>
          <Grid
            container
            item
            xs={12}
            sm={11}
            md={9}
            style={{ margin: ".5rem auto" }}
          >
            <IconButton
              component={motion.div}
              initial={{
                opacity: 0,
                x: 200,
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.5 },
              }}
              color="primary"
              onClick={handleDialogOpen}
              style={{
                height: "50px",
                width: "50px",
                marginLeft: 0,
              }}
            >
              <ArrowBackIosRoundedIcon
                style={{ fontSize: "40px", padding: 0 }}
              />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={11}
            md={9}
            style={{ margin: ".5rem auto" }}
          >
            <Typography style={{ alignSelf: "flex-start" }} variant="caption">
              * - pola wymagane
            </Typography>
            <ProjectController
              control={control}
              propName="name"
              defaultValue={existingProjectData?.name}
              inputClass={classes.inputClass}
              label="Nazwa projektu"
              error={Boolean(errors?.name)}
              helperText={errors?.name && "Uzupełnij nazwę"}
              required={true}
            />
            <div
              className="select-container required"
              style={{ margin: ".6rem 0" }}
            >
              <Controller
                control={control}
                name={"client.id"}
                render={(field) => (
                  <Select
                    {...field}
                    placeholder="Klient"
                    rules={{ required: true }}
                    options={clientsOptions}
                    styles={{
                      container: styleSelectContainer,
                      control: styleSelectControl,
                      input: styleSelectInput,
                    }}
                    onChange={(
                      inputValue: {
                        label: string;
                        value: number | undefined;
                      } | null
                    ): void => {
                      if (inputValue) {
                        setValue(`client.id`, inputValue.value);
                      }
                    }}
                    defaultValue={
                      existingProjectData
                        ? {
                            label: existingProjectData?.client?.name,
                            value: existingProjectData?.client?.id,
                          }
                        : null
                    }
                  />
                )}
              />
            </div>
            <div
              className="select-container required"
              style={{ margin: ".6rem 0" }}
            >
              <Controller
                control={control}
                name={"supervisor"}
                render={(field) => (
                  <Select
                    {...field}
                    placeholder="Nadzorca"
                    rules={{ required: true }}
                    options={supervisorOptions}
                    styles={{
                      container: styleSelectContainer,
                      control: styleSelectControl,
                      input: styleSelectInput,
                    }}
                    onChange={(
                      inputValue: {
                        label: string | undefined;
                        value: string | undefined;
                      } | null
                    ): void => {
                      if (inputValue) {
                        setValue(`supervisor`, inputValue.label!);
                      }
                    }}
                    defaultValue={
                      existingProjectData
                        ? {
                            label: existingProjectData?.supervisor,
                            value: existingProjectData?.supervisor,
                          }
                        : null
                    }
                  />
                )}
              />
            </div>
            <Typography
              align="left"
              variant="body1"
              style={{ color: "#7f7f7f" }}
            >
              Opis projektu
            </Typography>
            <div
              style={{
                width: "100%",
                margin: "0 auto .6rem",
                padding: ".4rem",
                border: "1px solid hsl(0, 0%, 80%)",
                borderRadius: "4px",
                background: "rgba(255, 255, 255, .9)",
                boxShadow: " 2px 2px 10px 0 rgba(31, 38, 135, 0.37)",
              }}
              className="required"
            >
              <ErrorBoundary>
                <Editor
                  handlePastedText={() => false}
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                />
              </ErrorBoundary>
            </div>
            {errors.description && (
              <p className={errorMUIClassName} style={{ margin: 0 }}>
                {errors.description.message}
              </p>
            )}
            <Typography
              align="left"
              variant="body1"
              style={{ color: "#7f7f7f" }}
            >
              Typ projektu
            </Typography>
            <div className="select-container">
              <Controller
                control={control}
                name={"type"}
                render={(field) => (
                  <CreatableSelect
                    {...field}
                    className="required"
                    styles={{
                      container: styleSelectContainer,
                      control: styleSelectControl,
                      input: styleSelectInput,
                    }}
                    placeholder="Typ projektu"
                    defaultValue={type}
                    options={typesOptions}
                    onChange={(inputValue: OptionsType | null): void => {
                      setType(inputValue!);
                    }}
                    formatCreateLabel={(inputValue) => handleFormat(inputValue)}
                    onCreateOption={(value) => dispatch(createType(value))}
                  />
                )}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => setEditTypeOpen(true)}
              >
                Edytuj
              </Button>
            </div>
            {errors.type && (
              <p className={errorMUIClassName} style={{ margin: 0 }}>
                {(errors.type as any)?.message}
              </p>
            )}
            <Typography
              align="left"
              variant="body1"
              style={{ color: "#7f7f7f" }}
            >
              Technologie
            </Typography>
            <div className="select-container">
              <CreatableSelect
                className="required"
                placeholder="Technologie"
                closeMenuOnSelect={false}
                isMulti
                blurInputOnSelect={false}
                styles={{
                  container: styleSelectContainer,
                  control: styleSelectControl,
                  input: styleSelectInput,
                }}
                options={stackOptions}
                value={stack}
                onChange={(value: any) => setStack(value)}
                formatCreateLabel={(inputValue) => handleFormat(inputValue)}
                onCreateOption={(value) => dispatch(createStack(value))}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => setEditStackOpen(true)}
              >
                Edytuj
              </Button>
            </div>
            {
              <p className={errorMUIClassName} style={{ margin: 0 }}>
                {(errors.stacks as any)?.message}
              </p>
            }
            <ProjectDatePicker
              {...commonDatePickerProps}
              label="Data wykonania projektu"
              propName="created"
            />
            <ProjectController
              control={control}
              propName="git"
              defaultValue={existingProjectData?.git}
              inputClass={classes.inputClass}
              label="GIT"
              error={Boolean(errors?.git)}
              helperText={errors?.git && "Uzupełnij GITa"}
              required={false}
            />
            <ProjectController
              control={control}
              propName="teamwork"
              defaultValue={existingProjectData?.teamwork}
              inputClass={classes.inputClass}
              label="Teamwork"
              error={Boolean(errors?.teamwork)}
              helperText={errors?.teamwork && "Uzupełnij link do teamworka"}
              required={false}
            />
            <div className="form__repetitiv-component-box form__repetitiv-component-box--screens">
              {Boolean(existingProjectData?.screens.length) && (
                <div className="form__current-screens-box">
                  <ScreensGallery
                    id={actualId!}
                    screens={existingProjectData?.screens}
                  />
                </div>
              )}
              <FileInput name="screens" control={control} />
              <div className="form__screens-box">
                {preview &&
                  preview?.map((url, index) => (
                    <div key={`image_${index}`}>
                      <img src={url} alt="podgląd zrzutów" />
                    </div>
                  ))}
              </div>
            </div>
            <div className="form__repetitiv-component-box form__repetitiv-component-box--team">
              <Team
                existingProjectData={existingProjectData}
                teamArr={teamArr}
                control={control}
                errors={errors}
                inputClass={classes.inputClass}
                setValue={setValue}
              />
            </div>
            <div className="form__repetitiv-component-box form__repetitiv-component-box--history">
              <History
                existingProjectData={existingProjectData}
                historyArr={historyArr}
                control={control}
                errors={errors}
                inputClass={classes.inputClass}
                setValue={setValue}
                watch={watch}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={buttonStyle}
            >
              Zatwierdź
            </Button>
          </Grid>
        </>
      )}
    </motion.form>
  );
}
