import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { motion } from "framer-motion";

interface ButtonAddProps {
  onClick: any;
}

export default function ButtonAdd({ onClick }: ButtonAddProps): JSX.Element {
  return (
    <IconButton
      component={motion.div}
      whileHover={{ boxShadow: "1px 1px 7px" }}
      color="primary"
      onClick={onClick}
      style={{
        width: 32,
        height: 32,
        margin: "0 .25rem",
        fontSize: "40px",
      }}
    >
      <AddCircleOutlineRoundedIcon style={{ fontSize: "inherit" }} />
    </IconButton>
  );
}
