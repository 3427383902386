import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchStacks } from "../../features/stacks/stacksSlice";
import { ReactComponent as Arrow } from "../../assets/down-arrow.svg";

interface FilterByStackProps {
  updateStack: (id: number) => void;
  stack: number[];
}

export default function FilterByStack({
  updateStack,
  stack,
}: FilterByStackProps): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchStacks());
  }, [dispatch]);

  const stacksData = useAppSelector((state) => state.stacks.data);

  const [isShowAll, setIsShowAll] = useState<Boolean>(false);

  return (
    <div className="filter__box">
      <Typography variant="h6" component="legend" className="filter__legend">
        Technologie
      </Typography>
      <FormGroup style={{ marginLeft: "1rem" }}>
        {stacksData &&
          stacksData.map(({ id, label }, index) =>
            isShowAll || index < 5 ? (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    style={{ padding: ".3rem" }}
                    color="primary"
                    id={`${id}`}
                    checked={Boolean(stack.find((elem) => elem === id))}
                    onChange={(e) => updateStack(Number(e.currentTarget.id))}
                  />
                }
                label={label}
              />
            ) : null
          )}
      </FormGroup>
      {stacksData && stacksData.length > 5 && (
        <Button
          className="filter__show-more-button"
          onClick={() => setIsShowAll(!isShowAll)}
        >
          <Arrow
            style={{
              marginRight: "5px",
              transform: isShowAll ? "rotate(180deg)" : undefined,
            }}
          />
          {isShowAll ? "Ukryj" : "Pokaż więcej"}
          <Arrow
            style={{
              marginLeft: "5px",
              transform: isShowAll ? "rotate(180deg)" : undefined,
            }}
          />
        </Button>
      )}
    </div>
  );
}
