import * as React from "react";
import { Link, MemoryRouter, Route } from "react-router-dom";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useAppDispatch } from "../app/hooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import IProjectData from "../interface/IProjectData";

type PaginationLinkProps = {
  count: number;
  initialEntries: string;
  fetch: AsyncThunk<IProjectData[] | undefined, number, {}>;
};

export default function PaginationLink({
  count,
  initialEntries,
  fetch,
}: PaginationLinkProps) {
  const dispatch = useAppDispatch();
  return (
    <MemoryRouter initialEntries={[`${initialEntries}`]} initialIndex={0}>
      <Route>
        {({ location }) => {
          const query = new URLSearchParams(location.search);
          const page = parseInt(query.get("page") || "1", 10);
          return (
            <Pagination
              variant="outlined"
              color="primary"
              page={page}
              onChange={(e, page) => {
                page > 1
                  ? dispatch(fetch((page - 1) * 10))
                  : dispatch(fetch(0));
              }}
              count={Math.ceil(count / 10)}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`${initialEntries}${
                    item.page === 1 ? "" : `?page=${item.page}`
                  }`}
                  {...item}
                />
              )}
            />
          );
        }}
      </Route>
    </MemoryRouter>
  );
}
