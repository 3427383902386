import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config.js";
import IWorker from "../../interface/IWorker";

interface InitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  updateSelect: boolean;
  errorMessage?: string;
  data: IWorker[];
}

const initialState: InitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  updateSelect: false,
  errorMessage: "",
  data: [
    {
      name: "",
      id: 0,
    },
  ],
};

export const fetchMembers = createAsyncThunk(
  "members/fetchMembers",
  async (): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/members`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.members);
    }
  }
);

export const fetchMembersPage = createAsyncThunk(
  "members/fetchMembersPage",
  async (startAt: number): Promise<any> => {
    try {
      const response = await fetch(
        `${API_URL}/members?_start=${startAt}&_limit=10`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.members);
    }
  }
);

export const createMember = createAsyncThunk(
  "members/createMembers",
  async ({ name, mail, phone, company }: IWorker, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/members`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          name,
          company,
          mail,
          phone,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchMembersPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.members);
    }
  }
);

export const editMember = createAsyncThunk(
  "members/editMembers",
  async (
    { name, company, mail, phone, id }: IWorker,
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/members/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          name,
          mail,
          phone,
          company,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchMembersPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.members);
    }
  }
);

export const deleteMember = createAsyncThunk(
  "members/deleteMember",
  async (id: number, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/members/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchMembersPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.members);
    }
  }
);

export const membersSlice = createSlice({
  name: "membersData",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.updateSelect = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.data = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchMembers.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchMembers.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(createMember.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(createMember.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createMember.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(deleteMember.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(deleteMember.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(deleteMember.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchMembersPage.fulfilled, (state, action) => {
      state.data = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchMembersPage.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchMembersPage.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearState } = membersSlice.actions;

export const membersSelector = (state: RootState) => state.members;

export default membersSlice.reducer;
