import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import IProjectData from "../../interface/IProjectData";
import { API_URL } from "../../config.js";

interface InitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: string;
  data: { name: string; id: number }[];
}

const initialState: InitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  data: [],
};

export const fetchProjectsQuery = createAsyncThunk(
  "projects/fetchProjectsQuery",
  async (): Promise<IProjectData[] | undefined> => {
    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data: IProjectData[] = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const projectsQuerySlice = createSlice({
  name: "projectsQuery",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectsQuery.fulfilled, (state, action) => {
      state.data = action.payload?.map(({ name, id }) => {
        return { name, id };
      })!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchProjectsQuery.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchProjectsQuery.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearState } = projectsQuerySlice.actions;

export const projectsQuerySelector = (state: RootState) => state.projectsQuery;

export default projectsQuerySlice.reducer;
