import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import IProjectData from "../../interface/IProjectData";
import { UseFieldArrayReturn } from "react-hook-form";
import { Control } from "react-hook-form";
import { DeepMap } from "react-hook-form";
import { FieldError } from "react-hook-form";
import { UseFormSetValue } from "react-hook-form";
import { UseFormWatch } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import ProjectController from "./ProjectController";
import ButtonDelete from "../Buttons/ButtonDelete";
import ButtonAdd from "../Buttons/ButtonAdd";

type HistoryProps = {
  existingProjectData?: IProjectData;
  historyArr: UseFieldArrayReturn<IProjectData, "history", "id">;
  control: Control<IProjectData>;
  errors: DeepMap<IProjectData, FieldError>;
  inputClass: string;
  setValue: UseFormSetValue<IProjectData>;
  watch: UseFormWatch<IProjectData>;
};

export default function History({
  existingProjectData,
  historyArr,
  control,
  errors,
  inputClass,
  setValue,
  watch,
}: HistoryProps): JSX.Element {
  const { fields, append, remove } = historyArr;

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [indexToDelete, setIndexToDelete] = useState<number>();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć tę pozycję?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Nie</Button>
          <Button
            onClick={() => {
              remove(indexToDelete);
              handleClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      {fields.map((item, index) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ width: "100%", margin: "0 auto" }}
            key={`${item.id}`}
          >
            <Grid item lg={5}>
              <ProjectController
                control={control}
                propName={`history.${index}.description` as const}
                defaultValue={
                  (existingProjectData?.history &&
                    existingProjectData?.history[index]?.description) ||
                  ""
                }
                inputClass={inputClass}
                label="Zmiana w projekcie"
                error={Boolean(
                  errors?.history && errors?.history[index]?.description
                )}
                helperText={
                  errors?.history &&
                  errors?.history[index]?.description &&
                  "Uzupełnij opis zmiany w projekcie"
                }
                required={false}
              />
            </Grid>
            <Grid item lg={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name={`history.${index}.date` as const}
                  className={inputClass}
                  value={null || Date.parse(watch(`history.${index}.date`))}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id={`date-picker-history_${index}`}
                  label="Data dokonania zmiany"
                  error={Boolean(errors?.history?.[index]?.date)}
                  helperText={
                    errors?.history?.[index]?.date &&
                    "Podaj datę dokonania zmiany."
                  }
                  onChange={(e, option) => {
                    e &&
                      setValue(
                        `history.${index}.date`,
                        moment(e).format("YYYY-MM-DD")
                      );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "history date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <ButtonDelete
              id={`history${index}`}
              onClick={() => {
                handleOpen();
                setIndexToDelete(index);
              }}
            />
          </Grid>
        );
      })}
      <Grid
        container
        justifyContent="flex-start"
        style={{ width: "100%", margin: "0 auto" }}
      >
        <ButtonAdd
          onClick={() => {
            append({ description: "", date: "" });
          }}
        />
      </Grid>
    </>
  );
}
