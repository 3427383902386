import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import IProjectData from "../../interface/IProjectData";
import IHistory from "../../interface/IHistory";
import { API_URL } from "../../config.js";
import IProjectInitialState from "../../interface/IProjectInitialState";
import qs from "qs";

const initialState: IProjectInitialState = {
  list: {
    data: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    isPaginationDisplay: true,
    errorMessage: "",
  },
  current: {
    data: {
      id: 0,
      name: "",
      supervisor: "",
      description: "",
      type: {
        label: "",
        id: 0,
      },
      stacks: [],
      git: "",
      teamwork: "",
      team: {
        members: [
          {
            member: {
              name: "",
              id: 0,
            },
            responsibilities: "",
          },
        ],
        id: 0,
      },
      history: [],
      client: {
        name: "",
        contactPerson: "",
        email: "",
        phone: "",
      },
    },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
};

export const fetchProjectsData = createAsyncThunk(
  "projects/fetchProjectsData",
  async (): Promise<IProjectData[] | undefined> => {
    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const fetchProjectsPage = createAsyncThunk(
  "projects/fetchProjectsPage",
  async (startAt: number): Promise<IProjectData[] | undefined> => {
    try {
      const response = await fetch(
        `${API_URL}/projects?_start=${startAt}&_limit=10`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
          },
        }
      );
      let data: IProjectData[] = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const fetchProject = createAsyncThunk(
  "projects/fetchProject",
  async (id: string): Promise<IProjectData | undefined> => {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data: IProjectData = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (id: number, thunkAPI): Promise<IProjectData[] | undefined> => {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchProjectsPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const addProject = createAsyncThunk(
  "projects/addProject",
  async (
    projectData: IProjectData,
    thunkAPI
  ): Promise<IProjectData | undefined> => {
    const formData = new FormData();

    const {
      name,
      git,
      teamwork,
      created,
      description,
      supervisor,
      type,
      team,
      history,
      stacks,
      client,
      screens,
    } = projectData;

    const dataStringified = JSON.stringify({
      name,
      git,
      teamwork,
      created,
      description,
      supervisor,
      type,
      team: {
        members: team.members.map(({ responsibilities, member }) => {
          return {
            responsibilities,
            member: { name: member.name, id: member.id },
          };
        }),
      },
      history: history.map(({ description, date }) => {
        return {
          description,
          date,
        };
      }),
      stacks: stacks.map(({ id }) => id),
      client: client.id?.toString(),
    });
    if (projectData.screens) {
      formData.append("data", dataStringified);
      Array.from(screens).forEach((elem: any) => {
        formData.append(`files.screens`, elem, elem.name);
      });
    }

    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },

        body: screens ? formData : dataStringified,
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchProjectsPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const editProject = createAsyncThunk(
  "projects/editProject",
  async (
    projectData: IProjectData,
    thunkAPI
  ): Promise<IProjectData | undefined> => {
    const formData = new FormData();

    const {
      id,
      name,
      git,
      teamwork,
      created,
      description,
      supervisor,
      type,
      team,
      history,
      stacks,
      client,
      screens,
    } = projectData;

    const dataStringified = JSON.stringify({
      name,
      git,
      teamwork,
      created,
      description,
      supervisor,
      type,
      team: {
        members: team.members.map(({ responsibilities, member }) => {
          return {
            responsibilities,
            member: { name: member.name, id: member.id },
          };
        }),
      },
      history: history.map(({ description, date }) => {
        return {
          description,
          date,
        };
      }),
      stacks: stacks.map(({ id }) => id),
      client: client.id?.toString(),
    });

    formData.append("data", dataStringified);

    if (screens) {
      Array.from(screens).forEach((file: any) => {
        formData.append("files.screens", file, file.name);
        formData.append("ref", "project");
        formData.append("refId", id.toString());
        formData.append("field", "screens");
      });
    }

    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: screens ? formData : dataStringified,
      });
      let data = await response.json();

      console.log("response", data);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchProjectsPage(0));
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const addHistory = createAsyncThunk(
  "projects/addHistory",
  async (
    {
      projectHistory,
      id,
      current,
    }: { projectHistory: IHistory[]; id: string; current: IHistory },
    thunkAPI
  ): Promise<IHistory | undefined> => {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          history: [...projectHistory, current],
        }),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const editScreens = createAsyncThunk(
  "projects/editScreens",
  async (
    { id, screens }: { id: number; screens: number[] },
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          screens: screens.map((elem) => {
            return { id: elem };
          }),
        }),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const fetchFiltredProjects = createAsyncThunk(
  "projects/fetchFiltredProjects",
  async ({
    stack,
    type,
  }: {
    stack: number[];
    type: number[];
  }): Promise<IProjectData[] | undefined> => {
    const query = qs.stringify({
      _where: [stack ? { stacks: stack } : null, type ? { type: type } : null],
    });

    try {
      const response = await fetch(`${API_URL}/projects?${query}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data: IProjectData[] = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const fetchSerchedProjects = createAsyncThunk(
  "projects/fetchSearchedProjects",
  async (searchPhrase: string): Promise<IProjectData[] | undefined> => {
    const query = `name_contains=${searchPhrase}`;
    try {
      const response = await fetch(`${API_URL}/projects?${query}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data: IProjectData[] = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const projectsSlice = createSlice({
  name: "projectsData",
  initialState,
  reducers: {
    clearState: (state) => {
      state.current.isError = false;
      state.current.isSuccess = false;
      state.current.isFetching = false;
      state.list.isError = false;
      state.list.isSuccess = false;
      state.list.isFetching = false;
      state.list.isPaginationDisplay = true;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectsData.fulfilled, (state, action) => {
      state.list.data = action.payload!;
      state.list.isFetching = false;
      state.list.isSuccess = true;
      state.list.isPaginationDisplay = true;
      return state;
    });

    builder.addCase(fetchProjectsData.rejected, (state, action) => {
      console.log("payload", action);
      state.list.isFetching = false;
      state.list.isError = true;
      state.list.errorMessage = action.error.message;
    });

    builder.addCase(fetchProjectsData.pending, (state) => {
      state.list.isFetching = true;
    });

    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.list.data = action.payload!;
      state.list.isFetching = false;
      state.list.isSuccess = true;
      return state;
    });

    builder.addCase(deleteProject.rejected, (state, action) => {
      console.log("payload", action);
      state.list.isFetching = false;
      state.list.isError = true;
      state.list.errorMessage = action.error.message;
    });

    builder.addCase(deleteProject.pending, (state) => {
      state.list.isFetching = true;
    });

    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.current.data = action.payload!;
      state.current.isFetching = false;
      state.current.isSuccess = true;
      return state;
    });

    builder.addCase(fetchProject.rejected, (state, action) => {
      console.log("payload", action);
      state.current.isFetching = false;
      state.current.isError = true;
      state.current.errorMessage = action.error.message;
    });

    builder.addCase(fetchProject.pending, (state) => {
      state.current.isFetching = true;
    });

    builder.addCase(fetchFiltredProjects.fulfilled, (state, action) => {
      state.list.data = action.payload!;
      state.list.isFetching = false;
      state.list.isSuccess = true;
      state.list.isPaginationDisplay = false;
      return state;
    });

    builder.addCase(fetchFiltredProjects.rejected, (state, action) => {
      console.log("payload", action);
      state.list.isFetching = false;
      state.list.isError = true;
      state.list.errorMessage = action.error.message;
    });

    builder.addCase(fetchFiltredProjects.pending, (state) => {
      state.list.isFetching = true;
    });

    builder.addCase(fetchProjectsPage.fulfilled, (state, action) => {
      state.list.data = action.payload!;
      state.list.isFetching = false;
      state.list.isSuccess = true;
      state.list.isPaginationDisplay = true;
      return state;
    });

    builder.addCase(fetchProjectsPage.rejected, (state, action) => {
      console.log("payload", action);
      state.list.isFetching = false;
      state.list.isError = true;
      state.list.errorMessage = action.error.message;
    });

    builder.addCase(fetchProjectsPage.pending, (state) => {
      state.list.isFetching = true;
    });

    builder.addCase(fetchSerchedProjects.fulfilled, (state, action) => {
      state.list.data = action.payload!;
      state.list.isFetching = false;
      state.list.isSuccess = true;
      state.list.isPaginationDisplay = false;
      return state;
    });

    builder.addCase(fetchSerchedProjects.rejected, (state, action) => {
      console.log("payload", action);
      state.list.isFetching = false;
      state.list.isError = true;
      state.list.errorMessage = action.error.message;
    });

    builder.addCase(fetchSerchedProjects.pending, (state) => {
      state.list.isFetching = true;
    });
  },
});

export const { clearState } = projectsSlice.actions;

export const projectsSelector = (state: RootState) => state.projects;

export default projectsSlice.reducer;
