import { NavLink, useHistory } from "react-router-dom";
import "../styles/Nav.scss";
import { AppBar, Toolbar, Container, Button, Box } from "@material-ui/core";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { ReactComponent as LoginIcon } from "../assets/login.svg";
import logo from "../assets/DVS.svg";

type NavProps = {
  setKey: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const activeStyle = {
  height: "100%",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff",
};

export default function Nav({ setKey }: NavProps) {
  const history = useHistory();
  return (
    <AppBar position="static" style={{ boxShadow: "none", position: "fixed" }}>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <img className="nav__logo" src={logo} alt="Da Vinci studio logo" />
      </Box>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Toolbar
          color="text.primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
          }}
        >
          <div>
            <NavLink
              onClick={() => setKey(Math.random())}
              exact
              to="/projects"
              activeStyle={activeStyle}
            >
              Projekty
            </NavLink>
            <NavLink
              onClick={() => setKey(Math.random())}
              to="/form"
              activeStyle={activeStyle}
            >
              Dodaj projekt
            </NavLink>
            <NavLink
              onClick={() => setKey(Math.random())}
              to="/workers"
              activeStyle={activeStyle}
            >
              Pracownicy
            </NavLink>
            <NavLink
              onClick={() => setKey(Math.random())}
              to="/clients"
              activeStyle={activeStyle}
            >
              Klienci
            </NavLink>
          </div>
          {localStorage.getItem("JWT") ? (
            <Button
              color="inherit"
              style={{ marginRight: "1rem" }}
              variant="outlined"
              onClick={() => {
                localStorage.removeItem("JWT");
                window.location.reload();
              }}
            >
              Wyloguj się
              <span
                style={{
                  width: "20px",
                  marginLeft: ".6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LogoutIcon />
              </span>
            </Button>
          ) : (
            <Button
              onClick={() => history.push("/login")}
              variant="outlined"
              color="inherit"
              style={{ margin: ".6rem" }}
            >
              <span
                style={{
                  width: "20px",
                  marginRight: ".6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LoginIcon />
              </span>
              Logowanie
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
