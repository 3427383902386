import React, { ReactNode } from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, ...rest }: { children: ReactNode }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("JWT") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location, error: "Proszę się zalogować." },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
