import React, { useEffect } from "react";
import { TextField, Button, Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetPassword,
  clearState,
} from "../../features/user/resetPasswordSlice";
import IResetPassword from "../../interface/IResetPassword";
import { toast, Toaster } from "react-hot-toast";
import { useHistory } from "react-router";

const code = new URLSearchParams(document.location.search).get("code");

export default function ResetPassword(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const resetIsSuccess = useAppSelector(
    (state) => state.resetPassword.reset.isSuccess
  );

  const history = useHistory();
  const onSubmit = (data: IResetPassword) => {
    data.code = code!;
    dispatch(resetPassword(data));
  };

  useEffect(() => {
    resetIsSuccess && history.push("/login");
    return () => {
      dispatch(clearState());
    };
  });

  useEffect(() => {
    if (errors.passwordConfirmation?.type === "validate") {
      toast.error("Hasła różnią się od siebie.", {
        position: "top-center",
        duration: 3000,
      });
    }
    if (errors.passwordConfirmation?.type === "minLength") {
      toast.error("Zbyt krótkie hasło.", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [errors.passwordConfirmation]);

  return (
    <div style={{ paddingTop: "12%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Toaster />
        <Paper
          elevation={3}
          style={{
            width: "50%",
            minWidth: "360px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "1rem auto",
            padding: "1.2rem 3%",
            background: "rgba(255, 255, 255, 0.25)",
            borderRadius: "10px",
            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
            backdropFilter: "blur(4px)",
            border: "1px solid rgba(255, 255, 255, 0.18)",
          }}
        >
          <TextField
            {...register("password", { minLength: 8, required: true })}
            variant="outlined"
            type="password"
            label="nowe hasło"
            style={{
              minWidth: "220px",
              margin: ".6rem 10% 0",
              alignSelf: "stretch",
            }}
            error={Boolean(errors?.password)}
            helperText={
              errors?.password && "Hasło musi zawierać co najmniej 8 znaków"
            }
          ></TextField>
          <TextField
            {...register("passwordConfirmation", {
              minLength: 8,
              required: true,
              validate: (value) => {
                if (value === getValues()["password"]) {
                  return true;
                } else {
                  return false;
                }
              },
            })}
            variant="outlined"
            type="password"
            label="powtórz hasło"
            style={{
              margin: ".6rem 10%",
              minWidth: "220px",
              alignSelf: "stretch",
            }}
            error={Boolean(errors?.passwordConfirmation)}
            helperText={
              errors?.passwordConfirmation &&
              console.log(errors.passwordConfirmation)
            }
          ></TextField>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ padding: ".5rem 1rem" }}
          >
            Potwierdź
          </Button>
        </Paper>
      </form>
    </div>
  );
}
