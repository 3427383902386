import { Grid, Paper, Typography, Button, IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  useParams,
  useHistory,
  NavLink,
  useRouteMatch,
  Route,
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { useEffect } from "react";
import { fetchProject } from "../features/projects/projectsDataSlice";
import Loader from "react-loader-spinner";
import ProjectTimeline from "./ProjectTimeline";
import ProjectDetailsDesc from "./ProjectDetailsDesc";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "1rem 3rem",
      background: "rgba(255, 255, 255, 0.25)",
      boxShadow: "0 17px 20px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba(255, 255, 255, 0.18)",
    },
  })
);

const activeLinkStyle = {
  background: "#3f51b5",
  color: "#fff",
};

const navLinkStyle = {
  padding: "1rem 2rem",
  transitionDuration: ".6s",
};

export default function ProjectDetails(): JSX.Element {
  let { id } = useParams<{ id: string }>();

  const match = useRouteMatch();

  const classes = useStyles();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProject(id));
  }, [dispatch, id]);

  let history = useHistory();

  const actualProject = useAppSelector((state) => state.projects.current.data);

  const isSuccess = useAppSelector((state) => state.projects.current.isSuccess);

  if (actualProject) {
    const { history: projectHistory } = actualProject;

    return isSuccess ? (
      <motion.div
        initial={{
          width: "50%",
          opacity: 0,
          x: "50vw",
        }}
        animate={{
          width: "100%",
          opacity: 1,
          x: 0,
          transition: { type: "tween" },
        }}
        exit={{
          x: -200,
          opacity: 0,
          transition: { duration: 0.5 },
        }}
        style={{
          padding: "1rem",
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <IconButton
            component={motion.div}
            color="primary"
            onClick={history.goBack}
            style={{
              height: "50px",
              width: "50px",
              marginLeft: 0,
            }}
          >
            <ArrowBackIosRoundedIcon style={{ fontSize: "40px", padding: 0 }} />
          </IconButton>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`/edit/${id}`)}
          >
            Edytuj
          </Button>
        </Grid>
        <Typography variant="h6" align="left">
          ID: {id}
        </Typography>
        <Grid container>
          <NavLink
            style={navLinkStyle}
            activeStyle={activeLinkStyle}
            to={`${match.url}/description`}
          >
            Opis
          </NavLink>
          <NavLink
            style={navLinkStyle}
            activeStyle={activeLinkStyle}
            to={`${match.url}/history`}
          >
            Historia
          </NavLink>
        </Grid>
        <Paper className={classes.paper} elevation={4}>
          <Route exact strict path={`${match.url}/description`}>
            <ProjectDetailsDesc actualProject={actualProject} />
          </Route>
          <Route path={`${match.url}/history`}>
            <ProjectTimeline projectHistory={projectHistory} id={id} />
          </Route>
        </Paper>
      </motion.div>
    ) : (
      <div style={{ margin: "8rem auto" }}>
        <h3>Pobieram dane projektu</h3>
        <Loader type="TailSpin" />
      </div>
    );
  } else {
    return (
      <div>
        <h3>Nie ma takiego projektu</h3>
      </div>
    );
  }
}
