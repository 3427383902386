import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IProjectData from "../interface/IProjectData";
import { convertDateStringToUIDate } from "../utils/convertDateStringToUIDate";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import moment from "moment";
import ScreensGallery from "./ScreensGallery";
import useWindowDimensions from "../hooks/useWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicPadding: {
      padding: ".5rem 1.5rem",
    },
    gridItem: {
      width: "100%",
      margin: ".5rem auto",
      background: "rgba(242, 250, 255, .8)",
      boxShadow: "0 5px 9px 0 rgba( 31, 38, 135, 0.37 )",
      borderRadius: "10px",
    },
  })
);

type ProjectDetailsDescProps = {
  actualProject: IProjectData;
};
const createMarkup = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export default function ProjectDetailsDesc({
  actualProject,
}: ProjectDetailsDescProps): JSX.Element {
  const {
    id: projectId,
    name,
    supervisor,
    description,
    type,
    stacks,
    git,
    teamwork,
    team,
    created,
    history,
    screens,
    client,
  } = actualProject;

  const { width } = useWindowDimensions();

  const stackArr: string[] = stacks.map(({ label }) => label);

  const classes = useStyles();

  return (
    <motion.div
      initial={{
        width: "50%",
        x: "50vw",
        opacity: 0,
      }}
      animate={{
        width: "100%",
        x: 0,
        opacity: 1,
        transition: { type: "tween" },
      }}
    >
      <Grid
        container
        direction="row"
        wrap="wrap"
        justifyContent="space-between"
        spacing={width < 1280 ? 0 : 5}
      >
        <Grid container item direction="column" lg={6} sm={12}>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Nazwa projektu:
            </Typography>
            <Typography className={classes.basicPadding} variant="body1">
              {name || "Brak nazwy"}
            </Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Klient:
            </Typography>
            <Typography className={classes.basicPadding} variant="body1">
              {client?.name || "Brak klienta"}
            </Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Opis:
            </Typography>
            <div
              style={{ textAlign: "left" }}
              className={classes.basicPadding}
              dangerouslySetInnerHTML={createMarkup(description)}
            ></div>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Zespół:
            </Typography>
            {team.members.map(({ member, responsibilities }) => {
              return Boolean(member.id) ? (
                <Grid
                  container
                  justifyContent="space-evenly"
                  key={`team_${member?.id}`}
                  style={{
                    display: "inline-grid",
                    gridTemplateColumns: "repeat(2, 50%)",
                  }}
                >
                  <Typography className={classes.basicPadding} variant="body1">
                    {member?.name}
                  </Typography>
                  <Typography className={classes.basicPadding} variant="body1">
                    {responsibilities}
                  </Typography>
                </Grid>
              ) : (
                <Typography className={classes.basicPadding} variant="body1">
                  Brak członków zespołu
                </Typography>
              );
            })}
          </Grid>
        </Grid>
        <Grid container item direction="column" lg={6} sm={12}>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Opiekun:
            </Typography>
            <Typography className={classes.basicPadding} variant="body1">
              {supervisor || "Brak opiekuna"}
            </Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Data powstania:
            </Typography>
            <Typography className={classes.basicPadding} variant="body1">
              {(created && convertDateStringToUIDate(created)) ||
                "Brak daty powstania"}
            </Typography>
          </Grid>
          {history.length > 0 ? (
            <Grid className={classes.gridItem}>
              <Typography
                className={classes.basicPadding}
                variant="subtitle1"
                align="left"
              >
                Data ostatniej zmiany:
              </Typography>
              <Typography className={classes.basicPadding} variant="body1">
                {moment(history[history.length - 1].date).format(
                  "DD-MM-YYYY"
                ) || "Brak daty ostatniej zmiany"}
              </Typography>
            </Grid>
          ) : null}
          {git === "undefined" || !Boolean(git) ? null : (
            <Grid className={classes.gridItem}>
              <Typography
                className={classes.basicPadding}
                variant="subtitle1"
                align="left"
              >
                GIT:
              </Typography>
              <Typography
                style={{ wordWrap: "break-word" }}
                className={classes.basicPadding}
                variant="body1"
              >
                {git}
              </Typography>
            </Grid>
          )}
          {teamwork === "undefined" || !Boolean(teamwork) ? null : (
            <Grid className={classes.gridItem}>
              <Typography
                className={classes.basicPadding}
                variant="subtitle1"
                align="left"
              >
                Teamwork:
              </Typography>
              <Typography
                style={{ wordWrap: "break-word" }}
                className={classes.basicPadding}
                variant="body1"
              >
                {teamwork}
              </Typography>
            </Grid>
          )}
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Typ:
            </Typography>
            <Typography className={classes.basicPadding} variant="body1">
              {type?.label || "Brak typu projektu"}
            </Typography>
          </Grid>
          <Grid className={classes.gridItem}>
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Technologie:
            </Typography>
            <Grid
              className={classes.basicPadding}
              container
              item
              justifyContent="center"
              direction="row"
            >
              {stackArr ? (
                stackArr.map((stack) => {
                  return (
                    <div>
                      <Typography
                        className={classes.basicPadding}
                        variant="body1"
                      >
                        {stack}
                      </Typography>
                    </div>
                  );
                })
              ) : (
                <Typography className={classes.basicPadding} variant="body1">
                  Brak technologii do wyświetlenia
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {Boolean(screens.length) && (
          <Grid
            style={{ width: "100%" }}
            className={(classes.gridItem, classes.basicPadding)}
          >
            <Typography
              className={classes.basicPadding}
              variant="subtitle1"
              align="left"
            >
              Zrzuty ekranu:
            </Typography>
            <ScreensGallery id={projectId} screens={screens} />
          </Grid>
        )}
      </Grid>
    </motion.div>
  );
}
