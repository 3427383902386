import React, { useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IHistory from "../interface/IHistory";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import moment from "moment";
import { useAppDispatch } from "../app/hooks";
import {
  addHistory,
  clearState,
  fetchProject,
} from "../features/projects/projectsDataSlice";
import { motion } from "framer-motion";

type ProjectTimelineProps = {
  projectHistory: IHistory[];
  id: string;
};

const boxStyle = {
  padding: "1rem 2rem",
  background: "rgba(242, 250, 255, .8)",
  boxShadow: "0px 1px 5px 0px rgba( 31, 38, 135, 0.37 )",
  borderRadius: "10px",
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalStyle: {
      position: "absolute",
      width: "50vw",
      height: "30vh",
      padding: "2rem 3rem",
      background: "rgba(255, 255, 255, 0.9)",
      boxShadow: "0 17px 20px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba(255, 255, 255, 0.18)",
    },
  })
);

export default function ProjectTimeline({
  projectHistory,
  id,
}: ProjectTimelineProps): JSX.Element {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IHistory>();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IHistory> = (data) => {
    const dataObject = {
      current: data,
      id,
      projectHistory,
    };
    dispatch(addHistory(dataObject));
    handleClose();
    dispatch(clearState());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(fetchProject(id));
  }, [dispatch, id]);

  const classes = useStyles();

  const reverseProjectHistoryArr = [...projectHistory].reverse();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
      initial={{
        width: "50%",
        x: "50vw",
        opacity: 0,
      }}
      animate={{
        width: "100%",
        x: 0,
        opacity: 1,
        transition: { type: "tween" },
      }}
    >
      <Button
        onClick={handleOpen}
        color="primary"
        variant="contained"
        style={{ alignSelf: "flex-start" }}
      >
        Dodaj wpis
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <motion.form
          onSubmit={handleSubmit(onSubmit)}
          initial={{
            opacity: 0,
            scale: 0.2,
            y: "50vh",
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { type: "spring" },
          }}
        >
          <Paper
            className={classes.modalStyle}
            style={{ left: "calc(50% - 25vw)", top: "calc(50% - 15vh)" }}
          >
            <Grid
              container
              justifyContent="space-between"
              style={{ width: "90%", margin: "0 auto" }}
              key={`${id}`}
            >
              <Grid item lg={6}>
                <Controller
                  name={`description`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Opis zmiany"
                      label="Opis zmiany"
                      error={Boolean(errors?.description)}
                      helperText={
                        errors?.description &&
                        "Uzupełnij opis zmiany w projekcie"
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item lg={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name={`date`}
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    value={Date.parse(watch(`date`))}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data dokonania zmiany"
                    error={Boolean(errors?.date)}
                    helperText={errors?.date?.message}
                    onChange={(e, option) => {
                      e && setValue("date", moment(e).format("YYYY-MM-DD"));
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "history date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "1rem auto" }}
                type="submit"
              >
                Zatwierdź
              </Button>
            </Grid>
          </Paper>
        </motion.form>
      </Modal>
      {projectHistory.length > 0 ? (
        reverseProjectHistoryArr.map(({ description, date }, index: number) => {
          return description !== "" ? (
            <Grid key={`${index}`} style={{ margin: ".6rem 0" }}>
              <Typography align="left">{date}</Typography>
              <Box style={boxStyle}>
                <Typography align="left">Opis zmiany:</Typography>
                <Typography>{description}</Typography>
              </Box>
            </Grid>
          ) : null;
        })
      ) : (
        <p>Brak wpisów o dokonanych zmianach</p>
      )}
    </motion.div>
  );
}
