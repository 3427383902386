import React from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { sendResetRequest } from "../../features/user/resetPasswordSlice";

export default function ResetRequest(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: { email: string }) => {
    dispatch(sendResetRequest(data));
  };
  const requestIsSuccess = useAppSelector(
    (state) => state.resetPassword.request.isSuccess
  );

  return (
    <Container
      maxWidth="md"
      style={{
        paddingTop: "12%",
      }}
    >
      {!requestIsSuccess ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper
            elevation={3}
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "1rem auto",
              padding: "1.2rem 2rem",
              background: "rgba(255, 255, 255, 0.25)",
              borderRadius: "10px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          >
            <Typography align="left">
              Podaj adres e-mail, na którym zarejestrowane jest konto. Na podany
              adres wyślemy link, pod którym będzie możliwe ustawienie nowego
              hasła.
            </Typography>
            <TextField
              {...register("email", { required: true })}
              variant="outlined"
              type="email"
              label="Podaj e-mail"
              style={{ margin: "2rem auto 1rem" }}
              error={Boolean(errors.email)}
              helperText={errors?.email && "Podaj adres e-mail"}
            ></TextField>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ padding: ".5rem 1rem" }}
            >
              Potwirdź
            </Button>
          </Paper>
        </form>
      ) : (
        <Typography>
          Sprawdź skrzynkę odbiorczą (również spam). Otrzymany w wiadomości link
          umożliwi Ci ustawienie nowego hasła.
        </Typography>
      )}
    </Container>
  );
}
