import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config.js";
import IClient from "../../interface/IClient";

interface InitialState {
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  updateSelect: boolean;
  errorMessage?: string;
  data: {
    list: IClient[];
    count: number;
  };
}

const initialState: InitialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  updateSelect: false,
  errorMessage: "",
  data: {
    list: [
      {
        name: "",
      },
    ],
    count: 0,
  },
};

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/clients`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.clients);
    }
  }
);

export const fetchClientsPage = createAsyncThunk(
  "clients/fetchClientsPage",
  async (startAt: number): Promise<any> => {
    try {
      const response = await fetch(
        `${API_URL}/clients?_start=${startAt}&_limit=10`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.clients);
    }
  }
);

export const createClient = createAsyncThunk(
  "clients/createClients",
  async (
    { name, email, phone, contactPerson }: IClient,
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/clients`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          name,
          contactPerson,
          email,
          phone,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchClients());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.clients);
    }
  }
);

export const editClient = createAsyncThunk(
  "clients/editClients",
  async (
    { name, contactPerson, email, phone, id }: IClient,
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/clients/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          contactPerson,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchClients());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.clients);
    }
  }
);

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (id: number, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/clients/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        thunkAPI.dispatch(fetchClients());
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data.clients);
    }
  }
);

export const fetchClientsCount = createAsyncThunk(
  "projects/fetchClientsCount",
  async (): Promise<number | undefined> => {
    try {
      const response = await fetch(`${API_URL}/clients/count`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        console.log("Error", response.status + " " + response.statusText);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
    }
  }
);

export const clientsSlice = createSlice({
  name: "clientsData",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.data.list = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchClients.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchClients.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(createClient.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(createClient.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(createClient.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(deleteClient.fulfilled, (state) => {
      state.updateSelect = true;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(deleteClient.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(deleteClient.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchClientsPage.fulfilled, (state, action) => {
      state.data.list = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchClientsPage.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchClientsPage.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(fetchClientsCount.fulfilled, (state, action) => {
      state.data.count = action.payload!;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });

    builder.addCase(fetchClientsCount.rejected, (state, action) => {
      console.log("payload", action);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    });

    builder.addCase(fetchClientsCount.pending, (state) => {
      state.isFetching = true;
    });
  },
});

export const { clearState } = clientsSlice.actions;

export const clientsSelector = (state: RootState) => state.clients;

export default clientsSlice.reducer;
