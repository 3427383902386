import React, { useState } from "react";
import "./styles/App.scss";
import { Container, makeStyles } from "@material-ui/core";
import ProjectsTable from "./components/ProjectsTable";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Nav from "./components/Nav";
import ProjectForm from "./components/ProjectForm";
import ProjectEdit from "./components/ProjectEdit";
import ProjectDetails from "./components/ProjectDetails";
import PrivateRoute from "./helpers/PrivateRoute";
import Login from "./components/Login";
import { AnimatePresence } from "framer-motion";
import { default as WorkersList } from "./components/Workers/List";
import { default as ClientsList } from "./components/Clients/List";

import ResetPassword from "./components/ResetPassword/ResetPassword";
import ResetRequest from "./components/ResetPassword/ResetRequest";

const useStyles = makeStyles({
  root: {
    position: "relative",
    top: "64px",
    padding: "1rem",
    background: "rgba( 255, 255, 255, 0.25 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    webkitBackdropFilter: "blur( 4px )",
    minHeight: "calc(100vh - 64px)",
  },
});

function App() {
  const location = useLocation();

  const [key, setKey] = useState<number>();

  const classes = useStyles();

  return (
    <div className="App ">
      <Nav setKey={setKey} />
      <Container maxWidth="lg" className={classes.root}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={key}>
            <Redirect exact from="/" to="/projects" />
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/reset-request">
              <ResetRequest />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <PrivateRoute>
              <Route strict path="/projects">
                <ProjectsTable />
              </Route>
              <Route path="/form">
                <ProjectForm />
              </Route>
              <Route path="/edit/:id">
                <ProjectEdit />
              </Route>
              <Route path="/project/:id">
                <ProjectDetails />
              </Route>
              <Route path="/workers">
                <WorkersList />
              </Route>
              <Route path="/clients">
                <ClientsList />
              </Route>
            </PrivateRoute>
          </Switch>
        </AnimatePresence>
      </Container>
    </div>
  );
}

export default App;
