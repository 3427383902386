import ProjectForm from "./ProjectForm";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { useEffect } from "react";
import { fetchProject } from "../features/projects/projectsDataSlice";
import Loader from "react-loader-spinner";

export default function ProjectEdit(): JSX.Element {
  let { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProject(id));
  }, [dispatch, id]);

  const actualProject = useAppSelector((state) => state.projects.current.data);

  const isSuccess = useAppSelector((state) => state.projects.current.isSuccess);

  return isSuccess && actualProject ? (
    <div>
      <ProjectForm existingProjectData={actualProject} />
    </div>
  ) : (
    <div style={{ margin: "8rem auto" }}>
      <h3>Pobieram dane projektu</h3>
      <Loader type="TailSpin" />
    </div>
  );
}
