import React, { Component } from "react";

export class ErrorBoundary extends Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Coś poszło nie tak... </h1>
          <p>(nie zamieszczaj emotikonów w kopiowanym tekście)</p>
        </>
      );
    }

    return this.props.children;
  }
}
