import {
  Box,
  TextField,
  Button,
  Link,
  Typography,
  Paper,
  InputAdornment,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  loginUser,
  userSelector,
  clearState,
} from "../features/user/userSlice";
import { toast, Toaster } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ReactComponent as LoginIcon } from "../assets/login.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface ILogin {
  identifier: string;
  password: string;
}

export default function Login(): JSX.Element {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isFetching, isSuccess, isError, errorMessage } =
    useAppSelector(userSelector);

  const onSubmit = (data: ILogin) => {
    dispatch(loginUser(data));
  };

  const username = useAppSelector(
    (state) => state.resetPassword.reset.data.username
  );

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error("Błędne hasło lub login", {
        position: "top-center",
        duration: 3000,
      });
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      history.push("/projects");
    }
  }, [dispatch, errorMessage, history, isError, isSuccess]);

  return (
    <div>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{
            paddingTop: "12%",
          }}
        >
          <Paper
            elevation={3}
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              margin: "1rem auto",
              padding: "1.2rem 2rem",
              background: "rgba(255, 255, 255, 0.25)",
              borderRadius: "10px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          >
            <Typography variant="h5">Proszę się zalogować.</Typography>
            <TextField
              {...register("identifier", { required: true })}
              variant="outlined"
              defaultValue={username}
              type="text"
              label="login"
              style={{ width: "60%", margin: "2rem auto .6rem" }}
              error={Boolean(errors.identifier)}
              helperText={errors?.identifier && "Uzupełnij login/e-mail"}
            ></TextField>
            <TextField
              {...register("password", { required: true })}
              variant="outlined"
              type={isPasswordVisible ? "text" : "password"}
              label="hasło"
              style={{ width: "60%", margin: ".6rem auto", marginBottom: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => {
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {!isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors?.password && "Uzupełnij hasło"}
            ></TextField>
            <Link
              href="/reset-request"
              onClick={() => <Redirect to="/reset-request" />}
              variant="body2"
              style={{ padding: 0, textDecoration: "underline" }}
            >
              Zapomniałeś hasła?
            </Link>
            {isFetching ? (
              <Loader type="TailSpin" />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: ".6rem auto", padding: ".5rem 1rem" }}
              >
                <span
                  style={{
                    width: "20px",
                    marginRight: ".6rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LoginIcon />
                </span>
                Zaloguj
              </Button>
            )}
          </Paper>
        </Box>
      </form>
    </div>
  );
}
