export const styleSelectControl = (base: any) => ({
  ...base,
  width: "100%",
  background: "rgba(255, 255, 255, .9)",
  borderRadius: "4px",
  boxShadow: " 2px 2px 10px 0 rgba(31, 38, 135, 0.37)",
  borderColor: "rgba(0, 0, 0, 0.23)",
});

export const styleSelectContainer = (base: any) => ({
  ...base,
  width: "100%",
});

export const styleSelectInput = (base: any) => ({
  ...base,
  padding: ".8rem 0",
});
