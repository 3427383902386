import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import IProjectData from "../../interface/IProjectData";
import { UseFieldArrayReturn, UseFormSetValue } from "react-hook-form";
import { Control } from "react-hook-form";
import { DeepMap } from "react-hook-form";
import { FieldError, Controller } from "react-hook-form";
import ProjectController from "./ProjectController";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchMembers } from "../../features/members/membersSlice";
import ButtondDelete from "../Buttons/ButtonDelete";
import ButtonAdd from "../Buttons/ButtonAdd";
import {
  styleSelectControl,
  styleSelectContainer,
  styleSelectInput,
} from "../../utils/reactSelectStyle";

type TeamProps = {
  existingProjectData?: IProjectData;
  teamArr: UseFieldArrayReturn<IProjectData, "team.members", "id">;
  control: Control<IProjectData>;
  errors: DeepMap<IProjectData, FieldError>;
  inputClass: string;
  setValue: UseFormSetValue<IProjectData>;
};

export default function Team({
  existingProjectData,
  teamArr,
  control,
  errors,
  inputClass,
  setValue,
}: TeamProps): JSX.Element {
  const { fields, append, remove } = teamArr;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  const membersData = useAppSelector((state) => state.members.data);
  const options: { label: string; value: number }[] = membersData?.map(
    ({ name, id }) => {
      return { label: name, value: id! };
    }
  ) || [{ label: "", value: 0 }];
  const getDefaultValue = (index: number) => {
    let defaultValue: typeof options;
    if (existingProjectData) {
      defaultValue = existingProjectData?.team.members.map(
        ({ member: { name, id } }) => {
          return { label: name, value: id! };
        }
      );
    } else {
      defaultValue = [];
    }
    return defaultValue[index];
  };

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [indexToDelete, setIndexToDelete] = useState<number>();
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć tę pozycję?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Nie</Button>
          <Button
            onClick={() => {
              remove(indexToDelete);
              handleClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      {fields.map((item, index) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ width: "100%", margin: "0 auto" }}
            key={`${item.id}`}
          >
            <Grid item lg={5} className="required">
              <Controller
                control={control}
                name={`team.members.${index}.member.name` as const}
                render={(field) => (
                  <Select
                    {...field}
                    rules={{ required: true }}
                    options={options}
                    styles={{
                      container: styleSelectContainer,
                      control: styleSelectControl,
                      input: styleSelectInput,
                    }}
                    onChange={(
                      inputValue: { label: string; value: number } | null
                    ): void => {
                      if (inputValue) {
                        setValue(
                          `team.members.${index}.member.name`,
                          inputValue.label
                        );
                        setValue(
                          `team.members.${index}.member.id`,
                          inputValue.value
                        );
                      }
                    }}
                    defaultValue={getDefaultValue(index)}
                  />
                )}
              />
            </Grid>
            <Grid item lg={5}>
              <ProjectController
                control={control}
                propName={`team.members.${index}.responsibilities` as const}
                defaultValue={
                  Boolean(existingProjectData?.team.members[index]?.member.id)
                    ? existingProjectData?.team.members[index]?.responsibilities
                    : ""
                }
                inputClass={inputClass}
                label="Funkcja w projekcie"
                error={Boolean(
                  errors?.team?.members &&
                    errors?.team.members[index]?.responsibilities
                )}
                helperText={
                  errors?.team?.members &&
                  errors?.team?.members[index]?.responsibilities &&
                  "Uzupełnij funkcję w projekcie"
                }
                required={true}
              />
            </Grid>
            <ButtondDelete
              id={`team${index}`}
              onClick={() => {
                handleOpen();
                setIndexToDelete(index);
              }}
            />
          </Grid>
        );
      })}
      <Grid
        container
        justifyContent="flex-start"
        style={{ width: "100%", margin: "0 auto" }}
      >
        <ButtonAdd
          onClick={() => {
            append({ member: { name: "" }, responsibilities: "" });
          }}
        />
      </Grid>
    </>
  );
}
