import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchSerchedProjects } from "../features/projects/projectsDataSlice";
import { useForm } from "react-hook-form";
import { fetchProjectsQuery } from "../features/projects/projectsQuerySlice";

const styleSelect = (base: any) => ({
  ...base,
  width: "90%",
  maxWidth: "300px",
  minWidth: "150px",
});

export default function SearchProject(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjectsQuery());
  }, [dispatch]);

  const projectsQuery = useAppSelector((state) => state.projectsQuery.data);

  interface Option {
    label: string;
    value: number;
  }

  const { handleSubmit, setValue } = useForm<{ phrase: string }>();
  const onSubmit = (data: { phrase: string }) => {
    dispatch(fetchSerchedProjects(data.phrase));
  };

  const options: Option[] = projectsQuery.map(({ name, id }) => {
    return { label: name, value: id };
  });

  const handleFormat = (inputValue: string) => {
    return `Szukaj "${inputValue}"`;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "0 auto",
        gap: "1rem",
      }}
    >
      <CreatableSelect
        styles={{ container: styleSelect }}
        placeholder="Wyszukaj projekt"
        options={options}
        onChange={(inputValue) => {
          inputValue && setValue("phrase", inputValue.label);
        }}
        formatCreateLabel={(inputValue) => handleFormat(inputValue)}
        onCreateOption={(inputValue) =>
          dispatch(fetchSerchedProjects(inputValue))
        }
      />
      <Button variant="contained" color="primary" type="submit">
        Szukaj
      </Button>
    </form>
  );
}
