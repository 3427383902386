import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  Grid,
  Button,
  Modal,
  TextField,
  Typography,
  TableBody,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import IClient from "../../interface/IClient";
import {
  clearState,
  createClient,
  deleteClient,
  editClient,
  fetchClients,
  fetchClientsPage,
  fetchClientsCount,
} from "../../features/clients/clientsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { iconButtonStyle } from "../../utils/iconButtonStyle";
import {
  StyledTableCell,
  StyledTableHeadRow,
  StyledTableRow,
} from "../ProjectsTable";
import { useStyles } from "../ProjectTimeline";
import Loader from "react-loader-spinner";
import validator from "validator";
import PaginationLink from "../PaginationLink";

const buttonHover = {
  scale: 1.1,
  transition: { duration: 0.3 },
  boxShadow: "1px 1px 7px",
};
const buttonTap = { scale: 0.9 };

export default function List(): JSX.Element {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IClient>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const initialState = { name: "", phone: "", email: "", contactPerson: "" };

  const handleClose = () => {
    setOpen(false);
    reset(initialState);
  };

  const dispatch = useAppDispatch();

  const validatePhoneNumber = (number: string) => {
    const isValidPhoneNumber = validator.isMobilePhone(number, "any");
    return isValidPhoneNumber;
  };

  const onSubmit: SubmitHandler<IClient> = (data) => {
    data.id ? dispatch(editClient(data)) : dispatch(createClient(data));
    handleClose();
    dispatch(clearState());
    dispatch(fetchClients());
  };

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchClientsPage(0));
    dispatch(fetchClientsCount());
  }, [dispatch]);

  const clientsState = useAppSelector((state) => state.clients);

  const {
    data: { list: clientsList },
    isSuccess,
    isFetching,
  } = clientsState;

  const [currentClient, setCurrentClient] = useState<IClient | undefined>();

  useEffect(() => reset(currentClient), [currentClient, reset]);

  const filterClients = (id: number) => {
    setCurrentClient(clientsList.find((elem) => elem.id === id));
  };

  const clientsCount = useAppSelector((state) => state.clients.data.count);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [clientToDelete, setClientToDelete] = useState<string>();
  const handleDialogClose = () => setDialogOpen(false);
  const handleDialogOpen = () => setDialogOpen(true);

  return (
    <motion.div
      initial={{
        width: "50%",
        opacity: 0,
        x: "50vw",
      }}
      animate={{
        width: "100%",
        opacity: 1,
        x: 0,
        transition: { type: "tween" },
      }}
      exit={{
        x: -200,
        opacity: 0,
        transition: { duration: 0.5 },
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Czy napewno chcesz usunąć projekt?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>Nie</Button>
          <Button
            onClick={() => {
              dispatch(deleteClient(+clientToDelete!));
              handleDialogClose();
            }}
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <motion.form
          onSubmit={handleSubmit(onSubmit)}
          initial={{
            scale: ".5",
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            x: "calc(50% - 25vw)",
            y: "calc(50% + 25vh)",
            transition: { type: "tween", duration: 0.6 },
          }}
        >
          <Paper
            className={classes.modalStyle}
            style={{
              minHeight: "500px",
              background: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0 17px 20px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          >
            <Grid
              container
              justifyContent="center"
              style={{ margin: "0 auto" }}
            >
              <Grid item>
                <Typography variant="h3" color="primary" align="center">
                  Dane klienta
                </Typography>
                <Controller
                  name={`name`}
                  shouldUnregister
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="required"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Nazwa klienta"
                      label="Nazwa klienta"
                      error={Boolean(errors?.name)}
                      helperText={errors?.name && "Podaj nazwę"}
                    />
                  )}
                />
                <Controller
                  name={`contactPerson`}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Osoba kontaktowa"
                      label="Osoba kontaktowa"
                    />
                  )}
                />
                <Controller
                  name={`email`}
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="E-mail"
                      label="E-mail"
                    />
                  )}
                />
                <Controller
                  name={`phone`}
                  control={control}
                  defaultValue={""}
                  rules={{
                    minLength: 6,
                    validate: (value) => {
                      if (value) {
                        return validatePhoneNumber(value);
                      }
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="tel"
                      style={{ width: "100%", padding: 0 }}
                      margin="normal"
                      variant="outlined"
                      placeholder="Numer telefonu"
                      label="Numer telefonu"
                      error={Boolean(errors?.phone)}
                      helperText={errors?.phone && "Podaj numer telefonu"}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "1rem auto" }}
                type="submit"
              >
                Zatwierdź
              </Button>
            </Grid>
          </Paper>
        </motion.form>
      </Modal>
      <Grid container justifyContent="flex-end" style={{ margin: "1rem 0" }}>
        <Button
          component={motion.div}
          color="primary"
          variant="contained"
          onClick={handleOpen}
        >
          Dodaj klienta
        </Button>
      </Grid>
      {clientsList?.length && isSuccess ? (
        <>
          <TableContainer
            component={Paper}
            style={{
              margin: "1rem auto",
              background: "rgba(255, 255, 255, 0.25)",
              borderRadius: "5px",
              boxShadow: " 0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(4px)",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableHeadRow>
                  <StyledTableCell align="center" size="small">
                    ID
                  </StyledTableCell>
                  <StyledTableCell align="center">Firma</StyledTableCell>
                  <StyledTableCell align="center">
                    Osoba kontaktowa
                  </StyledTableCell>
                  <StyledTableCell align="center">E-mail</StyledTableCell>
                  <StyledTableCell align="center">Nr. telefonu</StyledTableCell>
                  <StyledTableCell align="center">Akcje</StyledTableCell>
                </StyledTableHeadRow>
              </TableHead>
              <TableBody>
                {clientsList.map(
                  ({ id, name, email, phone, contactPerson }) => {
                    return (
                      <StyledTableRow key={id}>
                        <TableCell align="center" size="small">
                          {id}
                        </TableCell>
                        <TableCell align="center">{name}</TableCell>
                        <TableCell align="center">{contactPerson}</TableCell>
                        <TableCell align="center">{email}</TableCell>
                        <TableCell align="center">{phone}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title="usuń pracownika"
                            placement="top"
                            arrow
                          >
                            <IconButton
                              component={motion.div}
                              whileHover={buttonHover}
                              whileTap={buttonTap}
                              id={`${id}`}
                              onClick={(e: any) => {
                                setClientToDelete(e.currentTarget.id);
                                handleDialogOpen();
                              }}
                              style={iconButtonStyle("#d32f2f")}
                            >
                              <DeleteForeverTwoToneIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="edytuj klienta" placement="top" arrow>
                            <IconButton
                              component={motion.div}
                              whileHover={buttonHover}
                              whileTap={buttonTap}
                              id={`${id}`}
                              onClick={() => {
                                filterClients(id!);
                                handleOpen();
                              }}
                              style={iconButtonStyle("#ff9800")}
                            >
                              <EditRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationLink
            count={clientsCount}
            initialEntries={"/clients"}
            fetch={fetchClientsPage}
          />
        </>
      ) : isFetching ? (
        <div style={{ margin: "8rem auto" }}>
          <h3>Pobieram listę Klientów</h3>
          <Loader type="TailSpin" />
        </div>
      ) : (
        <h2 style={{ margin: "0 auto" }}>Brak Klientów do wyświetlenia</h2>
      )}
    </motion.div>
  );
}
