import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API_URL } from "../../config";
import IResetPassword from "../../interface/IResetPassword";

interface InitialState {
  request: {
    email: string;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
  };
  reset: {
    data: {
      password: string;
      passwordConfirmation: string;
      code: string;
      username: string;
    };
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
  };
}

const initialState: InitialState = {
  request: {
    email: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reset: {
    data: { password: "", passwordConfirmation: "", code: "", username: "" },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
};

export const sendResetRequest = createAsyncThunk(
  "resetPassword/sendResetRequest",
  async ({ email }: { email: string }, thunkAPI): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/auth/forgot-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword/sendResetPassword",
  async (
    { code, password, passwordConfirmation }: IResetPassword,
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await fetch(`${API_URL}/auth/reset-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          password,
          passwordConfirmation,
        }),
      });
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.request.isError = false;
      state.request.isSuccess = false;
      state.request.isFetching = false;
      state.reset.isError = false;
      state.reset.isSuccess = false;
      state.reset.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendResetRequest.fulfilled, (state, action) => {
      state.request.email = action.payload.email;
      state.request.isFetching = false;
      state.request.isSuccess = true;
      return state;
    });

    builder.addCase(sendResetRequest.rejected, (state, action) => {
      console.log("payload", action.payload);
      state.request.isFetching = false;
      state.request.isError = true;
      state.request.errorMessage = action.error.message;
    });

    builder.addCase(sendResetRequest.pending, (state) => {
      state.request.isFetching = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.reset.data = action.payload.user;
      state.reset.isFetching = false;
      state.reset.isSuccess = true;
      return state;
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      console.log("payload", action.payload);
      state.reset.isFetching = false;
      state.reset.isError = true;
      state.reset.errorMessage = action.error.message;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.reset.isFetching = true;
    });
  },
});

export const { clearState } = resetPasswordSlice.actions;

export const resetPasswordSelector = (state: RootState) => state.resetPassword;

export default resetPasswordSlice.reducer;
